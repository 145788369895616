<template>
  <ValidationObserver ref="wizardPage1" id="wizardPage1" name="wizardPage1">
    <div>
      <div v-if="appData">

        <policy-alert :app-data="appData" :is-read-only="isReadOnly" />

        <policy-summary :app-data="appData" />

        <instructions :instructions-text="instructions" />

        <endorsement-details :app-data="appData" :is-read-only="isReadOnly" />

        <ValidationProvider
          :rules="{ 'required-action': { allowFalse: false } }"
          name="appData.statusFlags.endorsementDetailsReviewed"
          v-slot="{ errors }"
        >
          <div class="row mt-10">
            <span
              class="switch switch-sm switch-outline switch-icon switch-primary ml-4"
            >
              <label>
                <input
                  type="checkbox"
                  :disabled="isReadOnly"
                  v-model="appData.statusFlags.endorsementDetailsReviewed.value"
                  @change="
                    $emit(
                      'updateServerTransactionStatus',
                      'endorsementDetailsReviewed'
                    )
                  "
                />
                <span></span>
              </label>
            </span>
            <label class="col-form-label font-size-h5 ml-2">
              I confirm that the above information has been reviewed and is
              complete.
            </label>
          </div>
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
  </ValidationObserver>
</template>

<style lang="scss"></style>

<script>
import PolicySummary from "../../../common/PolicySummary.vue";
import PolicyAlert from "../../../common/PolicyAlert.vue";
import Instructions from "../../../common/Instructions.vue";
import EndorsementDetails from "./EndorsementDetails.vue";

export default {
  name: "ReviewPage",

  components: {
    PolicySummary,
    PolicyAlert,
    Instructions,
    EndorsementDetails,
  },
  data() {
    return {};
  },

  props: {
    appData: Object,
    isReadOnly: Boolean,
  },

  methods: {
    updateServerTransactionStatus: function (flagName) {
      this.$emit("updateServerTransactionStatus", flagName);
    },
  },

  computed: {
    instructions: function () {
      return "Please enter the reason for the endorsement, the effective date of the endorsement and the required endorsement details below.";
    },
  },
};
</script>
